import React, { useEffect } from "react";
import Helmet from "react-helmet";
import HomepageLayout from "src/layouts/homepage";
import JSONData from "branding/site-metadata.json";
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

export default function Privacy() {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <HomepageLayout>
            <Helmet data={JSONData}><title>Privacy Policy</title></Helmet>
            <header style={{ background: "#f0f0f0", width: "100%", padding: "20px", textAlign: "center" }}>
                <img src="/img/carbonate-logo.png" alt="Main Logo" style={{ width: "180px" }}/>
            </header>
            <div
                name="termly-embed"
                data-id="bb2f7ceb-536e-4bfd-afd2-1156bf320579"
                data-type="iframe"
            ></div>
            <RawFooter />
        </HomepageLayout>
    );
}
